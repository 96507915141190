<template>
  <div>
    <div v-if="items?.length > 0"
         class="custom-competitors-data-table"
         :class="[{'side-paddings': sidePaddings}, classes]">
      <table :class="[{'no-header': noHeader, 'no-shadow': noShadow}]">
        <thead v-if="!noHeader">
        <tr>
          <th v-for="(headerItem, index) in headers"
              :key="index"
              :class="[headerItem.align ? [headerItem.align] : 'left', sortingMeta[sortingKeys.sorting] === headerItem.value ? 'active' : '', headerItem.headingClass]"
              :style="[{ width: headerItem.width ? headerItem.width : '', minWidth: headerItem.minWidth ? headerItem.minWidth : ''}]">
            <div class="inner-content">
              <div class="inner-content-wrap"
                   @click="sortClickHandler(headerItem.sortBy, headerItem.sortable)">
                <template v-if="headerItem.sortable">
                  <div class="sort-icons-block">
                    <svg-icon icon="arrow-solid-up"
                              class="icon icon-first"
                              :class="{active: sortingMeta[sortingKeys.sorting] === headerItem.value && sortingMeta[sortingKeys.direction] === 'asc'}"/>
                    <svg-icon icon="arrow-solid-down"
                              class="icon"
                              :class="{active: sortingMeta[sortingKeys.sorting] === headerItem.value && sortingMeta[sortingKeys.direction] === 'desc'}"/>
                  </div>
                </template>

                <span>
                  <template v-if="headerItem.tooltipText">
                    <custom-tooltip :width="headerItem.tooltipWidth"
                                    :padding-classes="'padding: 4px 0;'">
                      <template v-slot:slotTrigger>
                        {{ isMobile && headerItem.mobileText ? headerItem.mobileText : headerItem.text }}
                      </template>
                      <template v-slot:slotContent>
                        <span v-html="headerItem.tooltipText"></span>
                      </template>
                    </custom-tooltip>
                </template>
                <template v-else-if="headerItem.dynamicTitle">
                  <div class="dynamic-title">
                    <slot name="header-title"></slot>
                  </div>
                </template>
                <template v-else-if="headerItem.tabletText">
                  {{ isTablet && headerItem.tabletText ? headerItem.tabletText : headerItem.text }}
                </template>
                  <template v-else-if="headerItem.mobileText">
                  {{ isMobile && headerItem.mobileText ? headerItem.mobileText : headerItem.text }}
                </template>
                  <template v-else>
                  {{ headerItem.text }}
                </template>
                </span>
              </div>
            </div>
          </th>
        </tr>
        </thead>

        <tbody>
        <tr v-if="showTotals === 'top'" class="top-total">
          <slot name="totals">
          </slot>
        </tr>
        <tr v-for="(item, index) in items"
            :key="index">
          <template v-if="withShowMore">
            <template v-if="items?.length < showMoreCount">
              <slot name="items" :item="item">
                items list
              </slot>
            </template>
            <template v-else>
              <template v-if="!showMoreActive || (index < showMoreCount && showMoreActive)">
                <slot name="items" :item="item">
                  items list
                </slot>
              </template>
              <template v-else-if="index === items?.length - 1">
                <td class="show-more"
                    :colspan="showMoreColspan">
                  <button type="button"
                          class="show-more-btn"
                          @click="showMoreClickHandler">Show more
                  </button>
                </td>
              </template>
            </template>
          </template>
          <template v-else>
            <slot name="items" :item="item">
              items list
            </slot>
          </template>
        </tr>
        <tr v-if="showTotals === 'bottom'" class="bottom-total">
          <slot name="totals">
          </slot>
        </tr>
        <template v-if="withShowMore">
          <tr v-if="!showMoreActive && items?.length > 5">
            <td class="show-more"
                :colspan="showMoreColspan">
              <button type="button"
                      class="show-more-btn"
                      @click="showMoreClickHandler">Show less
              </button>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TableCheckbox from "@/components/UI/TableCheckbox";
import CustomDataTableSelect from "@/components/Forms/CustomDataTableSelect";
import {mapGetters} from "vuex";

export default {
  name: "DashboardPageDataTable",
  components: {
    'data-table-checkbox': TableCheckbox,
    'custom-data-table-select': CustomDataTableSelect,
  },
  emits: ['update-sort', 'show-more-clicked'],
  props: {
    fixedWidth: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array
    },
    items: {
      type: Array
    },
    itemsPerPage: {
      type: Number
    },
    rowsPerPageItems: {
      type: Array
    },
    pagination: {
      type: Object
    },
    sortingMeta: {
      type: Object
    },
    sortable: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    checkedTableItemsCount: {
      type: Number,
      required: false,
    },
    hasSelectionArray: {
      type: Array,
      default: () => []
    },
    sortingKeys: {
      type: Object,
      default: () => {
        return {
          direction: 'direction',
          sorting: 'sort_by'
        }
      }
    },
    showMoreOutsideTrigger: {
      type: Boolean,
      required: false,
    },
    resetToDefault: {
      type: Number,
      default: 0,
    },
    noHeader: {
      type: Boolean,
      required: false,
    },
    noShadow: {
      type: Boolean,
      required: false,
    },
    sidePaddings: {
      type: Boolean,
      required: false,
    },
    withShowMore: {
      type: Boolean,
      required: true,
    },
    showMoreCount: {
      type: Number,
      default: 5,
    },
    showMoreColspan: {
      type: Number,
      default: 3
    },
    classes: {
      type: String,
      required: false
    },
    showTotals: {
      type: String, // "top" or "bottom"
      default: null
    },
  },
  data() {
    return {
      deleteAllCheckboxValue: false,
      showMoreActive: true,
      hashCode: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 10),
    }
  },
  methods: {
    selectClicked(e) {
      this.$emit('update-sort', {
        per_page: e.value,
      });
    },
    sortClickHandler(order_by, sortable) {
      if (!sortable) {
        return;
      }
      let direction;
      if (order_by !== this.sortingMeta[this.sortingKeys.sorting]) {
        direction = 'desc';
      } else {
        direction = this.sortingMeta[this.sortingKeys.direction] === 'desc' ? 'asc' : 'desc';
      }

      const tempKeysObject = {
        [this.sortingKeys.direction]: direction,
        [this.sortingKeys.sorting]: order_by,
      }
      this.$emit('update-sort', tempKeysObject);
    },
    showMoreClickHandler() {
      this.showMoreActive = !this.showMoreActive;
      this.$emit('show-more-clicked', {hash: this.hashCode, expanded: !this.showMoreActive});
    },
  },
  computed: {
    ...mapGetters([
      'isMobile',
    ]),
    isTablet() {
      return window.innerWidth > 767 && window.innerWidth < 992;
    }
  },
  watch: {
    checkedTableItemsCount(newVal, oldVal) {
      if (newVal < oldVal) {
        this.deleteAllCheckboxValue = false;
      }
    },
    showMoreOutsideTrigger(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showMoreActive = oldVal;
      }
    },
    resetToDefault(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.showMoreActive = true;
      }
    }
  }
}
</script>

<style src="./styles.scss" lang="scss"></style>